import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import type { LinksFunction, MetaFunction, LoaderFunction, MetaFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
  LiveReload,
} from "@remix-run/react";
import acceptLanguage from "accept-language-parser";
import { LocaleProvider } from "./hooks/useLocale";

import tailwindStylesheetUrl from "./tailwind.css?url";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: tailwindStylesheetUrl },
];

// Import rootAuthLoader
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { ClerkApp } from "@clerk/remix";

export const meta: MetaFunction = ({data} : MetaFunctionArgs) => {
  return [{
    title: "Vixel Admin",
    'sentry-trace': data?.sentryTrace,
    baggage: data?.sentryBaggage,
  }];
};


// Export as the root route loader
export const loader: LoaderFunction = args => {
  return rootAuthLoader(args, ({ request }) => {
    const languages = acceptLanguage.parse(
      request.headers.get("Accept-Language") as string
    );

    // If somehow the header is empty, return a default locale
    if (languages?.length < 1) return "en-us";

    // If there is no region for this locale, just return the code
    if (!languages[0].region) return languages[0].code;

    return {
      locale: `${languages[0].code}-${languages[0].region.toLowerCase()}`,
    };
  });
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="h-full bg-gray-100">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {children}
        <ScrollRestoration />
        <Scripts />
        {/* <LiveReload /> */}
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();

  return (
    <LocaleProvider locale={data.locale}>
      <Outlet />
    </LocaleProvider>
  );
}

export default ClerkApp(App);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en" className="h-full bg-gray-100">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <h1>
          {isRouteErrorResponse(error)
            ? `${error.status} ${error.statusText}`
            : error instanceof Error
            ? error.message
            : "Unknown Error"}
        </h1>
        <h2>Not found?</h2>
        <Scripts />
      </body>
    </html>
  );
}
